<template>
<div class="card">
  <div class="container">
    <div class="img-block">
      <img :src="product.image_url">
    </div>
  </div>
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
export default {
  name: "Card",
  components: {Footer},
  mounted() {
    window.scrollTo(0,0)
  },
  data () {
    return {
      product: null
    }
  },
  async created() {
    this.product = (await this.axios.get(`/products/${this.$route.params.product}`)).data
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.card{
  background-color: $white;
     .img-block{
       width: 90%;
       margin: 0 auto;
       & > img{
         width: 100%;
         height: 100%;
         object-fit: cover;
     }
  }
}
</style>
